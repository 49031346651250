import React from "react"
import logo from "../../assets/img/logo/logo.webp"

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex align-items-center justify-content-center m-auto vh-100">
                 <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logo} className="mr-auto ml-auto mt-2" style={{maxWidth: "200px"}} alt="آنلاین شاپ ژیوا"/>
                </div>
                {/*<div class="loader"></div>*/}

            </div>
        )
    }
}

export default SpinnerComponent
